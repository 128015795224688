import React from "react"
import styled from "styled-components"
import { device } from "../base/device"
import { variable } from "../base/variables"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import backgroundImage from "../images/house.jpg"
import headshotImageJpg from "../images/Karen-sqr.jpg"
// import headshotImageWebp from "../images/headshot.webp"

const Hero = styled.div`
  color: #fff;
  margin-bottom: 45px;
  padding: 4rem 4rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.medium} {
    background-attachment: fixed;
    background-position: bottom center;
    min-height: 150px;
    margin-bottom: 90px;
    padding: 4rem;
  }

  @media ${device.jumbo} {
    background-position: bottom -150px center;
  }

  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #231c10;
    opacity: 0.5;
  }

  h1 {
    margin: 100px auto 0;
    color: #fff;
    text-align: center;
    font-weight: 300;
    max-width: 680px;
    position: relative;
    z-index: 2;
    font-size: 30px;

    @media ${device.medium} {
      font-size: 50px;
    }
  }
`
const Intro = styled.div`
  .intro {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: ${variable.maxWidthInner};
    margin: 0 auto 60px;
    padding: 0 25px;

    @media ${device.medium} {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
    }

    &-photo {
      order: 1;
      text-align: right;
      margin-bottom: ${variable.spacer};

      @media ${device.medium} {
        flex: 1 1 30%;
        order: 2;
        margin-bottom: 0;
      }

      img {
        width: 234px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    &-text {
      order: 2;

      @media ${device.medium} {
        flex: 1 1 70%;
        order: 1;
      }

      p {
        font-size: 20px;
        line-height: 34px;
        margin: 0 auto 30px;
        color: ${variable.colorLightGray};

        @media ${device.medium} {
          font-size: 28px;
          line-height: 42px;
          margin: 0 40px 30px 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`
const Section = styled.div`
  color: #333;
  margin: 0 auto 60px;
  padding: 60px 25px 0;
  border-top: 1px solid #eee;
  max-width: ${variable.maxWidthInner};

  table {
    border-collapse: collapse;
    width: 100%;

    @media ${device.medium} {
      width: 54%;
    }

    td,
    th {
      text-align: left;
      font-weight: 300;
      padding: 0 0 4px;
    }
  }

  .contact {
    @media ${device.medium} {
      display: flex;
      justify-content: space-between;
    }

    .emergency {
      background-color: #f8f8f8;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 35px;
      margin-top: ${variable.spacer};
      font-size: 1rem;
      line-height: 1.5rem;
      display: none;

      ul {
        margin: 0 0 0 15px;
        padding: 0;
      }

      li {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-text {
      flex: 1 1 40%;
    }

    &-form {
      flex: 1 1 60%;
    }
  }
`
const ContactForm = styled.div`
  fieldset {
    border: 0;
    margin: 40px 0 0 0;
    padding: 0;
    @media ${device.medium} {
      margin: 0 0 0 60px;
    }
  }

  legend {
    display: none;
  }

  .checkbox {
    display: flex;

    [type="checkbox"] {
      width: auto;
      padding: 0;
    }

    label {
      text-transform: none;
      display: inline-block;
      margin: -2px 0 0 10px;
      line-height: 1.1rem;
      max-width: 60%;
      letter-spacing: 0;
      cursor: pointer;
    }
  }

  p {
    margin-bottom: 1.5rem;
  }

  input:not([type="submit"]),
  textarea {
    border: 1px solid ${variable.colorBorder};
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: ${variable.defaultFont};
    font-size: 1rem;
    outline: none;
    padding: 8px;
    width: 100%;

    @media ${device.large} {
      padding: 12px;
    }
  }

  textarea {
    min-height: 140px;
    resize: none;
  }

  [type="submit"] {
    font-family: ${variable.defaultFont};
    outline: none;
    padding: 8px 26px;
  }

  input.error-field,
  textarea.error-field {
    border-color: ${variable.colorError};
  }

  input.error-field::-webkit-input-placeholder,
  textarea.error-field::-webkit-input-placeholder {
    color: ${variable.colorError};
  }

  label {
    color: ${variable.colorLightGray};
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 2px 0 0;
    text-transform: uppercase;
  }

  .error-message {
    color: ${variable.colorError};
  }

  &.thanks form,
  &.thanks p {
    display: none;
  }

  &.thanks .thanks-message {
    display: block;
  }

  .error-message,
  .thanks-message {
    display: none;
  }

  .error-message.active,
  .thanks-message.active {
    color: #f00;
    display: block;
    margin-bottom: 3rem;
  }
`
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero>
    </Hero>
    <Intro>
    <div className="intro">
        <div className="intro-photo">
          <picture>
            <source srcSet={headshotImageJpg} type="image/jpeg" />
            <img src={headshotImageJpg} alt="Karen Shannon" />
          </picture>
        </div>
        <div className="intro-text">
          <p>Welcome! I’m Karen Shannon.  I’ve been a counselor to individuals, couples, children, and families since 2009. I consider it a privilege to partner with my clients in ways that honor their goals and help them navigate the transformative and positive changes they want to see in their lives.</p>
          <p>I want to encourage you right now. It takes a lot of courage to reach out and ask for help when you’ve realized you may be stuck, but you’ve already taken the first step! I would be honored to come alongside you while you pursue the path to a full and meaningful life.</p>
        </div>
      </div>
    </Intro>
    <Section id="method">
      <h2>Training &amp; Method</h2>
      <p>
      I implement a holistic, therapeutic approach in my practice: my goal is to support people equitably in their emotional, physical, and spiritual needs.</p>
      <p>While I’m a trained Biblical Counselor, I'm skilled and experienced in serving clients of both religious and non-religious backgrounds who may be struggling with (but not limited to):</p>
      <ul>
        <li>Anxiety</li>
        <li>Depression</li>
        <li>Stress management</li>
        <li>Grief and loss</li>
        <li>Trauma</li>
        <li>Communication</li>
        <li>Strengthening relationships</li>
      </ul>
      <p>My 40+ years of experience in academic, government, corporate and faith-based organizations gives me a unique perspective as a counselor.</p>
      <p>When used in conjunction with my therapeutic training, my background has equipped me to provide individual career and life transition support, whole ministry support, executive coaching and guidance to leaders in a multitude of professions and situations. </p>
    </Section>
    <Section id="location">
      <h2>Location &amp; Fees</h2>
      <p>
      Currently, I will be providing all counseling services online. Please fill in your contact information below and I will happily provide a free consultation and we can discuss fees.  I believe counseling should be accessible to all, so we can discuss other options, if needed. I do not accept insurance at this time.
      </p>
    </Section>
    <Section id="contact">
      <h2>Contact</h2>
      <div className="contact">
        <div className="contact-text">
          <p>
            If you would like to schedule an appointment, please use this contact number or or click here to send a message.
          </p>
          <div className="emergency">
            <p>
              If this is an emergency, please stop and call 911 or use the
              resources below.
            </p>
            <ul>
              <li>
                The National Suicide Hotline:
                <br />
                1-800-273-8255
                <br />
                <a
                  href="https://suicidepreventionlifeline.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  suicidepreventionlifeline.org
                </a>
              </li>
              <li>
                Addiction:
                <br /> 1-800-395-8279
                <br />
                <a
                  href="https://www.help.org/drug-and-alcohol-rehab-centers-in-pennsylvania/"
                  target="_blank"
                  rel="noreferrer"
                >
                  help.org
                </a>
              </li>
              <li>
                Domestic Violence:
                <br /> 1-800-799-7233 <br /> Pennsylvania Coalition Against
                Domestic Violence
                <br />
                <a
                  href="https://www.pcadv.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  pcadv.org
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-form">
          <ContactForm>
            <form
              className="contact-form"
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <p className="error-message">Please fill in the fields below.</p>
              <fieldset>
                <legend>Contact form fields</legend>
                <p hidden>
                  <label>
                    Don't fill this out: <input name="b" />
                  </label>
                </p>
                <p>
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" />
                </p>
                <p>
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" />
                </p>
                <p>
                  <label htmlFor="referred">How were you referred?</label>
                  <input type="text" name="referred" id="referred" />
                </p>
                <p>
                  <label htmlFor="description">Other comments</label>
                  <textarea name="comments" id="comments"></textarea>
                </p>
                <p className="checkbox">
                  <input type="checkbox" id="agree" />
                  <label htmlFor="agree">
                    By submitting this form, you agree to be contacted at the
                    email or phone above
                  </label>
                </p>
                <input type="hidden" name="form-name" value="contact" />
                <input
                  type="submit"
                  value="Submit"
                  className="button button-submit"
                />
              </fieldset>
            </form>
          </ContactForm>
        </div>
      </div>
    </Section>
  </Layout>
)

export default IndexPage
